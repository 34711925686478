import { useQuery } from 'react-query';
import isSameDay from 'date-fns/isSameDay';

import { HttpClient, TApiError } from '../../_http';
import { TFeeder } from '../_models';

function filterTrainsForToday(trains: TFeeder[]) {
  return trains.filter(t => isSameDay(new Date(t.feederTrain.departureDate), new Date()));
}

async function getFeeder(feederId: string): Promise<TFeeder[]> {
  const response = await HttpClient.get<TFeeder[]>(`Subscription/GetFeederByNumber?feederNumber=${feederId}`);
  const activePtCars = response.filter(feeder => feeder.connectionPtcar);
  const feeders = filterTrainsForToday(activePtCars);
  return feeders;
}

export function useGetFeederByNumber(feederId: string) {
  return useQuery<TFeeder[], TApiError>(['getFeeder', feederId], () => getFeeder(feederId), {
    enabled: feederId.length >= 2,
  });
}
