import { useQuery } from 'react-query';

import { HttpClient, TApiError } from '../../_http';
import { TFeeder } from '../_models';

type TSubscriptionByFeederDateParams = {
  feederId: string;
  fromDate: string; // yyyy-MM-dd
  toDate: string; // yyyy-MM-dd
};

async function getSubscriptionsByFeederDate({ feederId, fromDate, toDate }: TSubscriptionByFeederDateParams): Promise<TFeeder[]> {
  const response = await HttpClient.get<TFeeder[]>(
    `Subscription/GetSubscriptionsByFeederDate?fromDate=${fromDate}&toDate=${toDate}`,
  );

  return response.filter(({ feederTrain, connectionPtcar }) => feederTrain.trainNumber === feederId && connectionPtcar);
}

export function useGetSubscriptionsByFeederDate(subscriptionsParams: TSubscriptionByFeederDateParams) {
  return useQuery<TFeeder[], TApiError>(
    ['getSubscriptions', subscriptionsParams.feederId],
    () => getSubscriptionsByFeederDate(subscriptionsParams),
    {
      enabled: subscriptionsParams.feederId.length >= 2,
    },
  );
}
